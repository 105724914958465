import { ElementType, FC, ReactNode } from 'react';

import { SidebarDim, SideBarWrapper } from './styles';

interface NavSideBarProps {
  active: boolean;
  side: 'left' | 'right';
  onClickClose?: () => void;
  as?: ElementType;
  isAdmin?: boolean;
  isSalesRep?: boolean;
  children?: ReactNode;
}

const NavSideBar: FC<NavSideBarProps> = ({
  active,
  side = 'left',
  children,
  onClickClose,
  as,
  isAdmin,
  isSalesRep,
}) => {
  const handleClose = () => {
    onClickClose && onClickClose();
  };

  return (
    <>
      {!isAdmin && !isSalesRep && (
        <SidebarDim
          active={active}
          onClick={handleClose}
          data-testid="sidebardim-id"
        />
      )}
      <SideBarWrapper
        data-testid="navSideBar"
        active={active}
        side={side}
        as={as}
        isAdmin={isAdmin}
        isSalesRep={isSalesRep}
      >
        {children}
      </SideBarWrapper>
    </>
  );
};

export default NavSideBar;

import { HrProps, StyledDivider } from './styles';

const Divider = ({
  padding,
  margin,
  mobileHidden,
  verticalAlign,
  marginTop,
  marginBottom,
  color,
  borderWidth,
}: HrProps) => (
  <StyledDivider
    {...{
      padding,
      verticalAlign,
      margin,
      mobileHidden,
      marginTop,
      marginBottom,
      color,
      borderWidth,
    }}
  />
);

export default Divider;

import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

// Hook
interface WindowSize {
  width?: number | undefined;
  height?: number | undefined;
}

interface DetectMobileDevice {
  isMobileDevice?: boolean | undefined;
  isIpadPro?: boolean | undefined;
}

export const useWindowHasMounted = () => {
  // Just to check if window object is already available
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    if (typeof window !== undefined) {
      setHasMounted(true);
    }
  }, []);

  return hasMounted;
};

export const useDetectMobileDevice = () => {
  const initialState: DetectMobileDevice = {
    isMobileDevice: undefined,
  };
  const [isMobileDevice, setIsMobileDevice] = useState(initialState);
  useEffect(() => {
    // There are plans to reduce the amount of information that User-Agent provides on each HTTP request
    // but as of right now it seems it won't affect the ability to distinguish between mobile and destkop
    // info here: https://blog.chromium.org/2021/05/update-on-user-agent-string-reduction.html
    const device =
      /iPad|iPhone|Android/i.test(navigator.userAgent) ||
      (/Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints > 2); // Macintosh and maxTouchPoints check for Ipad and Ipad Pro
    setIsMobileDevice({
      isMobileDevice: device,
    });
  }, []);

  return isMobileDevice;
};

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

  const initialState: WindowSize = {
    width: undefined,
    height: undefined,
  };
  const [windowSize, setWindowSize] = useState(initialState);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export const isMaxWidth = (
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  // | Partial<['xs', 'sm', 'md', 'lg', 'xl']>
) => {
  const windowSize = useWindowSize();
  const theme = useTheme();
  return (
    windowSize.width && windowSize.width <= parseInt(theme.breakpoints[size])
  );
};

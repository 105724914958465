import styled from 'styled-components';

interface AdminButtonNavigationContainerProps {
  active?: boolean;
  listItem?: boolean;
  forGuide?: boolean;
  simpleList?: boolean;
}

export const AdminButtonNavigationContainer = styled.a<AdminButtonNavigationContainerProps>`
  background-color: ${({ theme }) => theme.colors.grey[600]};
  width: ${({ listItem }) => (listItem ? '154px' : '60px')};
  justify-content: ${({ listItem }) => (listItem ? 'flex-start' : 'center')};
  padding-left: ${({ listItem }) => (listItem ? '1rem' : 0)};
  height: ${({ listItem }) => (listItem ? '40px' : '60px')};
  display: flex;
  align-items: center;

  p {
    display: ${({ listItem }) => (listItem ? 'block' : 'none')};
    color: ${({ theme }) => theme.colors.grey[0]};
    ${({ listItem }) => (!listItem ? 'padding-left: 1.125rem' : '')};
  }

  path {
    fill: ${({ theme }) => theme.colors.grey[0]};
    opacity: ${({ active }) => (active ? 1 : '40%')};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[600]};
  }
`;

export const ListItemContainer = styled.div<AdminButtonNavigationContainerProps>`
  ${({ forGuide }) =>
    forGuide ? '' : `margin-left: 3.5rem; position: absolute; display: none`};
  ${AdminButtonNavigationContainer} {
    width: 154px !important;
    padding-left: 0.5rem;
    padding-left: 1rem;
    &:nth-child(1) {
      padding-left: 1rem !important;
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.black[500]};
      p {
        color: ${({ theme }) => theme.colors.primary.main};
      }
    }
  }
`;

export const NavContainers = styled.div<AdminButtonNavigationContainerProps>`
  cursor: pointer;
  &:hover {
    path {
      fill: ${({ theme }) => theme.colors.grey[0]};
    }
    ${ListItemContainer} {
      display: block;
    }
    p {
      display: block;
    }
    ${AdminButtonNavigationContainer} {
      &:nth-child(1) {
        width: 210px;
        justify-content: flex-start;
        padding-left: 0.4rem;
      }
    }
  }
`;

import { useAuth } from 'modules/auth/providers/AuthProvider';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ElementType } from 'react';

import {
  AdminButtonNavigationContainer,
  ListItemContainer,
  NavContainers,
} from './style';

interface AdminButtonNavigationProps {
  LinkTitle: string;
  hrefLink: string;
  listItem?: Array<any>;
  Icon?: ElementType;
  active?: boolean;
  forGuide?: boolean;
  handleNavigationClick?: (link: string) => void;
}

const AdminButtonNavigation = ({
  Icon,
  LinkTitle,
  hrefLink,
  listItem,
  forGuide,
  handleNavigationClick,
}: AdminButtonNavigationProps) => {
  const { logout } = useAuth();
  const router = useRouter();
  const active = router.pathname === hrefLink;

  const callLogout = () => {
    logout('/auth/signin');
  };
  if (LinkTitle === 'Log Out') {
    return (
      <NavContainers>
        {Icon && (
          <AdminButtonNavigationContainer active={active} onClick={callLogout}>
            <Icon />
            <p>{LinkTitle}</p>
          </AdminButtonNavigationContainer>
        )}
      </NavContainers>
    );
  }

  const handleClick = () => {
    if (handleNavigationClick) {
      handleNavigationClick(hrefLink);
    } else {
      router.push(hrefLink);
    }
  };

  return (
    <NavContainers simpleList={!!Icon}>
      {Icon && (
        <AdminButtonNavigationContainer active={active} onClick={handleClick}>
          <Icon />
          <p data-testid="title-id">{LinkTitle}</p>
        </AdminButtonNavigationContainer>
      )}
      {listItem && typeof listItem !== 'boolean' && (
        <ListItemContainer forGuide={forGuide}>
          {listItem?.map((val) => (
            <Link
              passHref
              href={val.hrefLink}
              key={`${val.hrefLink}-${val.LinkTitle}`}
              legacyBehavior
            >
              <AdminButtonNavigationContainer
                active={active}
                listItem={listItem.length > 0}
                simpleList
              >
                <p>{val.LinkTitle}</p>
              </AdminButtonNavigationContainer>
            </Link>
          ))}
        </ListItemContainer>
      )}
    </NavContainers>
  );
};

export default AdminButtonNavigation;

import type { Nullable } from 'interfaces';
import React from 'react';
import type { FormState, UseFormSetValue } from 'react-hook-form';
import type { OptionsType } from 'react-select';
import type { OptionType } from 'renderer';
import theme from 'ui/theme/config';

import { colourStyles } from './styles';

export const useSelect = ({
  defaultSelectedValue,
  name,
  options,
  errorString,
  formState,
  state,
  variant,
  width,
  forceValueChange,
  setValue,
  onChange,
  singleValueCustomColor,
}: {
  singleValueCustomColor?: string;
  options: OptionsType<OptionType>;
  defaultSelectedValue: OptionType;
  formState?: FormState<Record<string, any>>;
  name: string;
  state: string;
  variant?: number;
  width?: string;
  errorString?: string;
  forceValueChange?: boolean;
  setValue?: UseFormSetValue<Record<string, any>>;
  onChange?(selectedData?: Nullable<OptionType>): void;
}) => {
  const selectedValue = options.find(
    (option) => option.value === defaultSelectedValue?.value
  );
  const [selectedData, setSelectedData] = React.useState<OptionType>();

  React.useEffect(() => {
    setSelectedData(selectedValue);
    if (selectedValue?.value && !formState?.isDirty) {
      setValue?.(name, selectedValue?.value, {
        shouldValidate: true,
      });
    } else if (forceValueChange) {
      setValue?.(name, selectedValue?.value);
    }
  }, [name, selectedValue, formState?.isDirty, forceValueChange]);

  if (errorString) state = 'danger';
  const styles = colourStyles(
    theme,
    state,
    variant,
    width,
    singleValueCustomColor
  );

  const handleChange = (selectedOption: OptionType) => {
    onChange?.(selectedOption);
    setSelectedData(selectedOption);
    if (selectedOption?.value) {
      setValue?.(name, selectedOption?.value, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue?.(name, undefined, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  return {
    selectedData,
    styles,
    handleChange,
  };
};

import styled from 'styled-components';

interface SideBarProps {
  active?: boolean;
  side: 'left' | 'right';
  isAdmin?: boolean;
  isSalesRep?: boolean;
}

interface SidebarDimProps {
  active?: boolean;
}

export const SideBarWrapper = styled.div<SideBarProps>`
  position: fixed;
  top: 0;
  z-index: ${({ isAdmin, isSalesRep }) => (isAdmin || isSalesRep ? 10 : 6)};
  width: ${({ isAdmin, isSalesRep }) =>
    isAdmin || isSalesRep ? '60px' : '350px'};
  max-width: 80%;
  min-height: 360px;
  height: 100%;
  ${({ isAdmin, isSalesRep }) =>
    isAdmin || isSalesRep
      ? ''
      : `
			padding-bottom: 1.375rem;
			box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5)
		`};
  background-color: ${({ theme }) => theme.colors.grey[0]};
  transition: 500ms;
  visibility: hidden;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  ${({ active, side }) => {
    if (side === 'left') {
      return active ? `visibility: visible;left: 0px;` : `left: -300px;`;
    } else {
      return active ? `visibility: visible;right: 0px;` : `right: -300px;`;
    }
  }}
`;

export const SidebarDim = styled.div<SidebarDimProps>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: transparent;
  width: 100vw;
  height: 100vh;

  transition: background-color 500ms;
  visibility: hidden;

  ${({ active }) =>
    active && `visibility: visible;background-color:rgba(0, 0, 0, 0.3)`}
`;

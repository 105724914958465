import styled from 'styled-components';

export const TimerLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TimerWrapper = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.lg};
  text-align: center;
  span {
    font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
  }
`;

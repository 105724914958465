import styled, { css } from 'styled-components';

interface SalesRepLinkProps {
  isActive: boolean;
}

export const SalesRepSubnavLinkProps = styled.a<SalesRepLinkProps>`
  display: flex;
  height: 2.95rem;
  width: 2.95rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.grey[400]};
  font-size: 0.75em;
  & > p {
    margin: 0;
  }
  & > svg {
    height: 2.95rem;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.grey[0]};
    `}
`;

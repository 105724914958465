import { SvgProps } from 'interfaces';
import styled from 'styled-components';
import { getVariantSvg } from 'ui/theme/helpers';
import { VariantTypes } from 'ui/uiTypes';

interface Props {
  variant?: VariantTypes;
}

export const Path = styled.path<Props>`
  ${getVariantSvg()}
`;

export const Svg = styled.svg<SvgProps>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  cursor: pointer;
`;

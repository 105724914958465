import styled from 'styled-components';

export interface HrProps {
  margin?: number | string;
  mobileHidden?: boolean;
  verticalAlign?: boolean;
  marginTop?: number;
  marginBottom?: number;
  color?: string;
  borderWidth?: number;
  padding?: string;
}

export const StyledDivider = styled.hr<HrProps>`
  padding: ${({ padding }) => (padding ? padding : '0')};
  margin: ${({ margin }) => (margin ? margin + 'rem' : '0.5rem 0')};
  border-style: solid;
  border-color: ${({ theme, color }) =>
    color ? color : theme.colors.grey[200]};
  border-width: ${({ borderWidth, verticalAlign }) =>
    verticalAlign
      ? `0px ${borderWidth || 1}px 0px 0px`
      : `${borderWidth || 1}px 0px 0px 0px`};
  ${({ marginTop }) =>
    marginTop &&
    `
		margin-top: ${marginTop}px;
	`}
  ${({ marginBottom }) =>
    marginBottom &&
    `
		margin-bottom: ${marginBottom}px;
	`}

  ${({ verticalAlign }) => (verticalAlign ? 'transform: rotate(180deg);' : '')}

  ${({ mobileHidden, theme }) =>
    mobileHidden &&
    `
		@media (max-width: ${theme.breakpoints.md}) {
			display: none;
		}
	`}
`;

import styled, { css } from 'styled-components';
import { VariantTypes } from 'ui/uiTypes';

import { getVariantBgColor } from '../helpers';

interface WrapperProps {
  variant?: VariantTypes;
  isAdmin?: boolean;
  isSalesRep?: boolean;
  isMobile?: boolean;
}

export const SalesRepWrapper = styled.div<WrapperProps>`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[600]};
  position: fixed;
  top: 0;
  z-index: 201;
  ${getVariantBgColor()};
  ${({ isMobile }) =>
    isMobile &&
    css`
      background-color: ${({ theme }) => theme.colors.grey[0]};
    `}

  @media print {
    display: none !important;
  }
`;

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.secondary};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  z-index: 201;
  ${getVariantBgColor()}

  ${(props) =>
    (props.isAdmin || props.isSalesRep) &&
    `
      box-shadow: none;
    `}


  @media print {
    display: none !important;
  }
`;

export const SideBarContainer = styled.div`
  background: ${({ theme }) => theme.colors.grey[600]};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 4rem;
`;

export const NavigationContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  width: 100%;
`;

export const NavItem = styled.li`
  left: 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[600]};
  }
`;

import Loader from 'ui/Loader';
import theme from 'ui/theme/config';

import { TimerLoaderWrapper, TimerWrapper } from './styles';

interface TimerLoaderProps {
  timeLeft: number;
}

export default function TimerLoader({ timeLeft }: TimerLoaderProps) {
  if (!timeLeft) return null;

  return (
    <TimerLoaderWrapper>
      <Loader
        toColor={theme.colors.primary.main}
        width="300px"
        height="300px"
      />
      <TimerWrapper>
        Restarting the Server please wait <span>{timeLeft}</span>
      </TimerWrapper>
    </TimerLoaderWrapper>
  );
}

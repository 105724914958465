import React from 'react';
import ReactSelect from 'react-select';

import {
  CustomOptionContainer,
  CustomOptionStyle,
  ErrorLabel,
  Label,
  SelectContainer,
} from './styles';
import type { OptionComponentType, SelectReactProps } from './types';
import { useSelect } from './useSelect';

const Select = React.forwardRef<any, SelectReactProps>(
  (
    {
      options,
      placeholder,
      state = '',
      name = '',
      label = '',
      instanceId,
      labelAlign,
      classNamePrefix = '',
      showLabel = true,
      errorString,
      noMargin,
      marginBottom,
      required,
      size,
      as,
      defaultSelectedValue,
      setValue,
      onChange,
      formState,
      bg,
      width,
      noDefaultColor,
      horizontalLabel,
      Component,
      isClearable,
      singleValueCustomColor,
      forceValueChange,
      variant,
      ...rest
    }: SelectReactProps,
    ref: any
  ) => {
    const { selectedData, styles, handleChange } = useSelect({
      options,
      defaultSelectedValue,
      formState,
      name,
      state,
      errorString,
      variant,
      width,
      setValue,
      onChange,
      singleValueCustomColor,
    });

    const CustomComponentOption = ({
      innerProps,
      isDisabled,
      label,
      data,
    }: OptionComponentType) => (
      <>
        {Component ? (
          <CustomOptionContainer {...innerProps}>
            <Component {...{ options, innerProps, isDisabled, label, data }} />
          </CustomOptionContainer>
        ) : (
          <CustomOptionStyle>{label}</CustomOptionStyle>
        )}
      </>
    );

    const components = Component
      ? {
          IndicatorSeparator: () => null,
          Option: CustomComponentOption,
          SingleValue: CustomComponentOption,
        }
      : {
          IndicatorSeparator: () => null,
        };
    return (
      <SelectContainer
        noMargin={noMargin}
        marginBottom={marginBottom}
        size={size}
        as={as}
        variant={variant}
        error={!!errorString}
        data-cy="key-select"
        role="list"
        noDefaultColor={noDefaultColor}
        horizontalLabel={horizontalLabel}
        data-testid={`test-select-${label || placeholder}`}
      >
        {label && showLabel && (
          <Label
            isRequired={Boolean(required)}
            htmlFor={name}
            align={labelAlign}
          >
            {label}
          </Label>
        )}
        <ReactSelect
          rules={{ required }}
          classNamePrefix={`select__${classNamePrefix}`}
          ref={ref}
          id={label}
          instanceId={instanceId || label}
          value={selectedData}
          defaultValue={selectedData}
          options={[...(options || [])]}
          isOptionDisabled={(option) => option.disabled}
          inputId={name}
          openMenuOnFocus={true}
          onChange={handleChange}
          data-testid={`test-select-${label || placeholder}-option`}
          isClearable={isClearable}
          {...{ components, placeholder, styles, ...rest }}
        />
        {errorString && (
          <ErrorLabel data-testid="select-error">{errorString}</ErrorLabel>
        )}
      </SelectContainer>
    );
  }
);

export default Select;
